<script lang="ts">
  import { onDestroy } from "svelte";
  import ChangeEmailPassword from "./ChangeEmailPassword.svelte";
  import Redirect from "./Redirect.svelte";
  import { logout } from "../auth";
  import { route } from "../util/router";
  import ChangeEmailName from "./ChangeEmailName.svelte";

  export let auth_token: string | null | undefined = null;
  export let view: string | null | undefined = null;
  export let redirect_uri: string;
  export let format: string | null | undefined = null;

  $: parsed = auth_token && JSON.parse(atob(auth_token.split(".")[1]));

  $: if (parsed?.change && "login" == view)
    (view = "change"), route("/login/change");

  //$: console.log("parsed=", parsed);

  // make sure to destroy and saved sessions
  // onDestroy(() => {
  //   logout();
  // });
</script>

{#if "login" == view}
  <Redirect {auth_token} {redirect_uri} {format} />
{:else if "change" == view && parsed.sso}
  <ChangeEmailName
    {auth_token}
    login_hint={parsed.email}
    email={parsed.email}
    name={parsed.name}
  />
{:else if "info" == view}
  <ChangeEmailName
    {auth_token}
    login_hint={parsed.email}
    email={parsed.email}
    name={parsed.name}
  />
{:else if "change" == view}
  <ChangeEmailPassword
    {auth_token}
    login_hint={parsed.email}
    email={parsed.email}
    name={parsed.name}
    required={parsed.password === false}
  />
{/if}
