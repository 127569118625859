<script lang="ts">
  import { logout } from "../auth";

  export let auth_token: string | null | undefined = null;
  export let redirect_uri: string;
  export let format: string | null | undefined = null;

  logout();
  if (format?.toLowerCase() === "query") {
    const url = new URL(redirect_uri);
    url.searchParams.set("access_token", auth_token);
    window.location.href = url.toString();
  } else {
    window.location.href =
      decodeURIComponent(redirect_uri).split("#")[0] +
      "#access_token=" +
      auth_token;
    //redirect_uri.set(null);
  }
</script>

<figure class="validating">Logging in…</figure>
