<script context="module" lang="ts">
  const redirect_uri = session("redirect_uri", "");
  params.subscribe(($params) => {
    if ($params.redirect_uri) redirect_uri.set($params.redirect_uri);
  });
</script>

<script lang="ts">
  import { fetchUpdateNameEmailPassword as update } from "../api";
  import { logout } from "../auth";
  import { session } from "../util/stores";
  import { params } from "../util/params";

  export let name: string | null | undefined = null;
  export let email: string | null | undefined = null;
  export let login_hint: string | null | undefined = null;
  export let auth_token: string | null | undefined = null;

  // export let redirect_uri: string | null | undefined = null;

  let submittable = false;
  let submitting = false;

  let success = null;
  let error = null;
  let form = null;

  function checkSubmittable(form) {
    return (submittable = !!(
      form &&
      form.checkValidity &&
      form.checkValidity()
    ));
  }

  function onchange(e) {
    return (submittable = checkSubmittable(e.target.form || e.target));
  }

  async function onsubmit(e) {
    e.preventDefault();

    if (submitting) return;

    const form = e.target;

    if (!checkSubmittable(form)) return;

    submitting = true;
    error = success = null;

    var json = await update(
      form.elements["name"].value,
      form.elements["email"].value,
      form.elements["password"]?.value,
      auth_token
    );

    if (json.message) {
      success = null;
      submitting = false;
      error = json.message;
      return;
    }

    logout();
    //logIn(login_hint);

    // handle errors

    location.href = `/login?login_hint=${escape(
      encodeURIComponent(login_hint || "")
    )}`;

    //console.log("result=", json);

    //form.reset();
  }
  function onreset(e: Event) {
    e.preventDefault();
    logout();
    location.href = e.target.value;
  }
</script>

<form
  bind:this={form}
  on:blur={onchange}
  on:change={onchange}
  on:input={onchange}
  on:submit={onsubmit}
>
  <fieldset>
    <ul>
      <li>
        <!-- <label for="login-email">Email</label> -->
        <input
          id="login-name"
          type="text"
          name="name"
          spellcheck="false"
          required
          value={name || ""}
          placeholder="Enter your name"
          autocomplete="name"
        />
      </li>
      <li>
        <!-- <label for="login-email">Email</label> -->
        <input
          id="login-email"
          type="email"
          name="email"
          spellcheck="false"
          required
          value={login_hint || ""}
          placeholder="Enter your login email"
          autocomplete="username"
        />
      </li>
    </ul>
  </fieldset>

  {#if error}
    <p class="error">{error}</p>
  {/if}
  <footer>
    <button type="submit" disabled={!submittable || submitting}
      >{submitting ? "Saving…" : "Save & Continue"}</button
    >
    {#if $redirect_uri}
      <button type="reset" value={$redirect_uri} on:click={onreset}
        >Cancel</button
      >
    {/if}
  </footer>
</form>
