<script context="module" lang="ts">
  import {
    zxcvbn,
    zxcvbnAsync,
    zxcvbnOptions,
    ZxcvbnResult,
  } from "@zxcvbn-ts/core";
  import * as zxcvbnCommonPackage from "@zxcvbn-ts/language-common";
  import * as zxcvbnEnPackage from "@zxcvbn-ts/language-en";
  import { matcherPwnedFactory } from "@zxcvbn-ts/matcher-pwned";
  const options = {
    translations: zxcvbnEnPackage.translations,
    graphs: zxcvbnCommonPackage.adjacencyGraphs,
    dictionary: {
      ...zxcvbnCommonPackage.dictionary,
      ...zxcvbnEnPackage.dictionary,
    },
  };

  zxcvbnOptions.setOptions(options);
  const matcherPwned = matcherPwnedFactory(fetch, zxcvbnOptions);
  zxcvbnOptions.addMatcher("pwned", matcherPwned);

  class PasswordValidator {
    #value: Writable<string>;
    #result: Readable<ZxcvbnResult>;

    constructor() {
      this.#value = writable("");
      this.#result = derived<Readable<string>, ZxcvbnResult>(
        this.#value,
        ($value, set) => {
          set(null);
          if (!$value) return;
          var result = zxcvbnAsync($value.toString());
          result.then(set);
        }
      );
    }

    get value(): Writable<string> {
      return this.#value;
    }
    get result(): Readable<ZxcvbnResult> {
      return this.#result;
    }
  }

  // function PasswordValidator(value: Readable<string>): Readable<ZxcvbnResult> {
  //   return derived(value, ($value) => zxcvbn($value));
  // }

  const redirect_uri = writable("");
  params.subscribe(($params) => {
    if ($params.redirect_uri) redirect_uri.set($params.redirect_uri);
  });
</script>

<script lang="ts">
  import { fetchUpdateNameEmailPassword as update } from "../api";
  import { logout } from "../auth";
  import debounce from "lodash-es/debounce";
  import { derived, writable } from "svelte/store";
  import type { Readable, Writable } from "svelte/store";
  import { session } from "../util/stores";
  import { params } from "../util/params";

  export let name: string | null | undefined = null;
  export let email: string | null | undefined = null;
  export let login_hint: string | null | undefined = null;
  export let auth_token: string | null | undefined = null;
  export let required: boolean | null | undefined = null;
  export let password: boolean | null | undefined = null;
  // export let redirect_uri: string | null | undefined = null;

  let submittable = false;
  let submitting = false;

  let success = null;
  let error = null;
  let form = null;
  const validator = new PasswordValidator();
  const pw = validator.result;

  $: passwordValid = ($pw?.score ?? -1) > 2;
  $: if (passwordValid) checkSubmittable(form);
  //$: console.log("validation", $pw);

  const scores = ["Weak", "Weak", "Fair", "Good", "Strong"];

  function checkSubmittable(form) {
    return (submittable = !!(
      form &&
      form.checkValidity &&
      form.checkValidity() &&
      passwordValid
    ));
  }

  function onchange(e) {
    return (submittable = checkSubmittable(e.target.form || e.target));
  }

  async function onsubmit(e) {
    e.preventDefault();

    if (submitting) return;

    const form = e.target;

    if (!checkSubmittable(form)) return;

    submitting = true;
    error = success = null;

    var json = await update(
      form.elements["name"].value,
      form.elements["email"].value,
      form.elements["password"].value,
      auth_token
    );

    if (json.message) {
      success = null;
      submitting = false;
      error = json.message;
      return;
    }

    logout();
    //logIn(login_hint);

    // handle errors

    location.href = `/login?login_hint=${escape(
      encodeURIComponent(login_hint || "")
    )}`;

    //console.log("result=", json);

    //form.reset();
  }

  const score = debounce(
    function (value: string) {
      validator.value.set(value);

      //if (!value || typeof value !== "string") return (complexity = null);

      //complexity = zxcvbn(value);
      //console.log("complexity=", complexity);

      //return (complexity = scored);
    },
    150,
    {
      leading: false,
      trailing: true,
    }
  );

  function onpassword(value: string | HTMLInputElement | Event) {
    if (!value) return score(null);
    if (value instanceof Event) value = value.target as HTMLInputElement;
    if (value instanceof HTMLInputElement) value = value.value;
    if (value?.target) value = value.target as HTMLInputElement;
    if (value?.value) value = value.value as string;
    if (typeof value != "string") value = value?.toString();
    score(value);

    //return (complexity = scored);
  }
  function onreset(e: Event) {
    e.preventDefault();
    logout();
    location.href = e.target.value;
  }
</script>

<form
  bind:this={form}
  on:blur={onchange}
  on:change={onchange}
  on:input={onchange}
  on:submit={onsubmit}
>
  <fieldset>
    <ul>
      <li>
        <!-- <label for="login-email">Email</label> -->
        <input
          id="login-name"
          type="text"
          name="name"
          spellcheck="false"
          required
          value={name || ""}
          placeholder="Enter your name"
          autocomplete="name"
        />
      </li>
      <li>
        <!-- <label for="login-email">Email</label> -->
        <input
          id="login-email"
          type="email"
          name="email"
          spellcheck="false"
          required
          value={login_hint || ""}
          placeholder="Enter your login email"
          autocomplete="username"
        />
      </li>
    </ul>
  </fieldset>
  <fieldset>
    <ul>
      <li>
        <!-- <label for="login-combo-email">Password</label> -->
        <input
          id="login-password"
          type="password"
          name="password"
          spellcheck="false"
          {required}
          autocomplete={required ? "new-password" : "off"}
          placeholder={required ? "Set your password" : "Change your password"}
          on:blur={onpassword}
          on:change={onpassword}
          on:input={onpassword}
        />
        <data class="password score" value={$pw?.score ?? ""}
          >{scores[$pw?.score ?? -1] ?? ""}</data
        >
      </li>
    </ul>
  </fieldset>
  {#if $pw?.feedback?.warning || $pw?.feedback?.suggestions?.length}
    <p class="error">
      {[$pw.feedback.warning, ...$pw.feedback.suggestions]
        .filter(Boolean)
        .join(" ")}
    </p>
  {/if}
  {#if error}
    <p class="error">{error}</p>
  {/if}
  <footer>
    <button type="submit" disabled={!submittable || submitting}
      >{submitting ? "Saving…" : "Save & Continue"}</button
    >
    {#if $redirect_uri}
      <button type="reset" value={$redirect_uri} on:click={onreset}
        >Cancel</button
      >
    {/if}
  </footer>
</form>
