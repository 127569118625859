import { Api } from "@parkingboss/api";
import pkg from "../package.json";
import { derived, Readable } from "svelte/store";

import type { User } from "@parkingboss/api/dist/cjs/api/loadUser";
export type { User };

export const client = pkg.name.split("/")[1] || pkg.name;

export const api = Api({
  client,
  //skipUrlRewrite: true,

});

export const auth = derived(api.user, ($auth, set) => {
  // no auth
  if (!$auth || !$auth.expires) return set(null);

  const window = 60 * 1000;

  function authExpire($auth, ms) {
    if (new Date($auth.expires).getTime() < new Date().getTime() + ms)
      return true;
  }

  const i = setInterval(() => {
    if (authExpire($auth, window)) set(null);
  }, window);

  if (!authExpire($auth, window)) set($auth);

  return () => clearInterval(i); // clear the clock
});

let $token;

export const token: Readable<string | null | undefined> = derived(auth, ($auth) => $auth && $auth.token);
//export const jwt: Readable<string | null | undefined> = derived(token, ($token) => $token?.access_token);
//export const user: Readable<Record<string, any>> = derived(token, ($token) => $token && JSON.parse(atob($token.split(".")[1])));

token.subscribe(($value) => {
  $token = $value;
});

export function logIn(email) {
  location.href = `https://auth.communityboss.app/login?login_hint=${escape(
    encodeURIComponent(email || "")
  )}&client_id=${client}&redirect_uri=${escape(
    encodeURIComponent(location.href)
  )}`;
}

export function logout() {
  api.logOut(true);
}


export function sign(url) {
  return url && url.split("#")[0] + "#access_token=" + $token;
}

